<template>
  <div class="about flex flex-col justify-center items-center">
    <h1>This is an about page</h1>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    };
  },
  computed: {},
};
</script>